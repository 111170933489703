/* Styles */

/*** General Spacing ***/
.gutter-top { margin-top: 15px; }
.gutter-left { margin-left: 15px; }
.gutter-right { margin-right: 15px; }
.gutter-bottom { margin-bottom: 15px; }

.gutter-top-sm { margin-top: 5px; }
.gutter-left-sm { margin-left: 5px; }
.gutter-right-sm { margin-right: 5px; }
.gutter-bottom-sm { margin-bottom: 5px; }

/*** Navigation Styles ***/
.sidebar-wrapper {
  display: flex;
}

.navbar-bottom {
  margin-bottom: 75px;
}

.sidebar-wrapper #sidebar {
    min-width: 200px;
    max-width: 200px;
    min-height: 100vh;
    padding-right: 15px;
}
  #sidebar .nav-icon {
    padding-right: 0.25em;
  }


/*** CutScene Modal ***/
div.scene-container:focus {
    outline: none;
}
  div.scene-container img {
    height: 300px;
  }
  div.scene-container h4 {
    padding-bottom: 5px;
    border-bottom: 1px solid #1c1e22
  }
  div.scene-container div.scene-text {
    min-height: 3em;
  }

  div.scene-container .blink {
    animation: blink 2.5s linear infinite;
  }
  @keyframes blink {
    0%{opacity: 0;}
    25%{opacity: .5;}
    50%{opacity: 1;}
    75%{opacity: .5;}
    100%{opacity: 0;}
  }

/*** Cutscene Dialogue Editor ***/
.dialogue-text-editor .text-entry {
  padding: 5px;
}
.dialogue-text-editor .text-entry > div:last-of-type {
  width: 100%;
}
.dialogue-text-editor .text-entry textarea.form-control {
  width: 100%;
  resize: vertical;
}
.dialogue-text-editor .text-entry:not(.active) .form-control {
  background-color: transparent;
  border-color: transparent;
  color: inherit;
  cursor: pointer;
  resize: none;
}

.dialogue-text-editor .text-entry .text-entry-controls {
  margin-top: 8px;
}
  .dialogue-text-editor .text-entry .text-entry-controls button:first-of-type {
    margin-right: 8px;
  }
  .dialogue-text-editor .text-entry .text-entry-controls .close {
    float: none;
  }

/* .task-entry {
  display:flex;
}
  .task-entry > div {
    display: inline-block;
  }
  .task-entry > div.checkbox-container {
    padding-top: 8px;
  }
  .task-entry > div:last-of-type {
    width: 100%;
  }
.task-entry textarea.form-control {
  width: 100%;
  resize: vertical;
}
.task-entry:not(.active) .form-control {
  background-color: transparent;
  border-color: transparent;
  color: inherit;
  cursor: pointer;
  resize: none;
}
.task-entry .task-entry-controls {
  margin-top: 8px;
}
  .task-entry .task-entry-controls button {
    margin-right: 8px;
  }
  .task-entry .task-entry-controls .close {
    float: none;
  } */
